import { Col, Container, Image, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3.8,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3.8,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1.4,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
export default function Teams() {
    return(
        <section className="teams-expert" id="team">
            <Container>
                <Row>
                    <Col md="12" lg="12" sm="12" xs="12">
                        <h1 className="teams-title">Our team of experts</h1>
                    </Col>
                </Row>
            </Container>
            <section className="teams-cards">
            <Carousel
                responsive={responsive}
                autoPlay={false}
                swipeable={true}
                draggable={true}
                showDots={false}
                infinite={false}
            >
                <div className="slider">
                    <div className="team-profile">
                        <div className="team-profile-image">
                            <Image src={process.env.PUBLIC_URL+'/team/vinod-achanta.png'} alt="Vinod Achanta" title="Vinod Achanta" />
                        </div>
                        <h6 className="primary">Vinod Achanta, Founder</h6>
                        <h6>Planning, Funding & Sales</h6>
                    </div>
                </div>
                <div className="slider">
                    <div className="team-profile">
                        <div className="team-profile-image">
                            <Image src={process.env.PUBLIC_URL+'/team/canchal-das.png'} alt="Chanchal Das" title="Chanchal Das" />
                        </div>
                        <h6 className="primary">Chanchal Das, Partner</h6>
                        <h6>Project Management & Operations</h6>
                    </div>
                </div>
            </Carousel>
            </section>
        </section>
    )
}