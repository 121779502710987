/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col, Container, Image, Row } from "react-bootstrap";
import Ainfralogo from '../assets/Ainfralogo.png'
import RightIcon from '../assets/right-icon.png'
import Facebook from '../assets/facebook.svg'
import Instagram from '../assets/instagram.svg'
import LinkedIn from '../assets/Linkedin.svg'
import Twitter from '../assets/twitter.svg'
import { HashLink } from "react-router-hash-link";
import { Link } from 'react-router-dom';
export default function Footer() {
    return(
        <section className="footer">
            <section className="footer-seperate-content">
                <Container>
                    <section className="footer-block">
                        <Row>
                            <Col md="2" lg="2" sm="2" xs="12">
                                <div className="footer-logo">
                                    <Image src={Ainfralogo} alt="Ainfralogo" title="Ainfralogo" className="w-100" />
                                </div>
                            </Col>
                            <Col md="10" lg="10" sm="10" xs="12">
                                <div className="footer-block-content">
                                    <Row>
                                        <Col md="4" lg="4" sm="4" xs="12">
                                            <div className="footer-content">
                                                <h5>Need to talk to us?</h5>
                                                <p><span>Email   :</span><span>vinod@ainfra.in</span></p>
                                                <p><span>Phone  :</span><span>+91 9160611160</span></p>
                                            </div>
                                        </Col>
                                        <Col md="3" lg="3" sm="3" xs="12">
                                            <div className="footer-content">
                                                <h5>Useful links</h5>
                                                <ul>
                                                    <li>
                                                    <HashLink to={process.env.PUBLIC_URL+'/#aboutus'}>
                                                        <Image src={RightIcon} alt="Right icon" title="Right icon" />
                                                        <span>About</span>    
                                                        </HashLink>
                                                    </li>
                                                    <li>
                                                        <a href="">
                                                        <Image src={RightIcon} alt="Right icon" title="Right icon" />
                                                        <span>Projects</span>    
                                                        </a>
                                                    </li>
                                                    {/* <li>
                                                        <a href="">
                                                        <Image src={RightIcon} alt="Right icon" title="Right icon" />
                                                        <span>FAQS</span>    
                                                        </a>
    </li> */}
                                                    <li>
                                                        <a href="">
                                                        <Image src={RightIcon} alt="Right icon" title="Right icon" />
                                                        <span>Gallery</span>    
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <HashLink to={process.env.PUBLIC_URL+'/#team'}>
                                                        <Image src={RightIcon} alt="Right icon" title="Right icon" />
                                                        <span>Team</span>    
                                                        </HashLink>
                                                    </li>
                                                    <li>
                                                        <Link to={process.env.PUBLIC_URL+'/privacy-policy'}>
                                                        <Image src={RightIcon} alt="Right icon" title="Right icon" />
                                                        <span>Privacy Policy</span>    
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </Col>
                                        <Col md="5" lg="5" sm="5" xs="12">
                                            <div className="footer-content">
                                                <div className="subtitle">
                                                <h4>Subham Homes</h4>
                                                    <h2>Our Quality Promise</h2>
                                                </div>
                                                <p className="text-right">Your partner for good quality , premium homes in Hyderabad.</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </section>
                </Container>
            </section>
            <section className="copyright">
                <Container>
                    <Row>
                        <Col md="12" lg="12" sm="12" xs="12">
                        <p>
                   <a>
                      <Image src={Facebook} alt="Facebook" title="Facebook" /> 
                   </a> 
                   <a>
                      <Image src={Instagram} alt="Instagram" title="Instagram" /> 
                   </a> 
                   <a>
                      <Image src={Twitter} alt="Twitter" title="Twitter" /> 
                   </a>  
                   <a>
                      <Image src={LinkedIn} alt="LinkedIn" title="LinkedIn" /> 
                   </a>    
                </p>    
                        </Col>
                    </Row>
                </Container>
            </section>  
        </section>
    )
}