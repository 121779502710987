import { Col, Container, Image, Row } from "react-bootstrap";

export default function AboutUs() {
    return(
        <section className="about-us" id="aboutus">
            <Container>
                <Row>
                    <Col md="12" lg="12" sm="12" xs="12">
                        <h2 className="section-title">About us</h2>
                        <p>AINFRA is a process driven, smart homes company which believes that every home has immense potential and value that can add to the owner's life. Our value system is based on "maximizing the potential of home in every square feet". Our floor plans well designed while taking care of vaastu and are highly functional modern plans.</p>
                        <p>AINFRA management has immense experience in Real Estate with some top notch brands in Hyderabad Market.</p>
                        <div className="mt-5 signature">
                            <Image src={process.env.PUBLIC_URL+'/images/VinodAchantasignature.svg'} alt="VinodAchantasignature" title="VinodAchantasignature" />
                         </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}