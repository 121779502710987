import axios from "axios"

export const ContactDetails = (data) => {
    return new Promise((resolve, reject) => {
        return axios.post(`https://ainfra.in/php/contactDetails.php`,{
            name: data.name,
            email: data.email,
            mobileNumber: data.mobileNumber
        }).then((res)=>{
            resolve(res)
        }).catch((err)=>{
            reject(err)
        })
    })
}