import { Col, Container, Row } from "react-bootstrap";
export default function Banner() {
    return(
        <section className="banner">
            <Container>
                <Row>
                    <Col md="12" lg="12" sm="12" xs="12">
                        <div className="banner-content">
                            <h3>Subham Homes, </h3>
                           <h2>Our Quality Promise.</h2> 
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="video-full">
                <video width="100%" height="100%" autoPlay loop id="video">
                    <source src={process.env.PUBLIC_URL+'/videos/HappyFamilyAinfra.mp4'} type="video/mp4" />
                    <source src="movie.ogg" type="video/ogg" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </section>
    )
}