/* eslint-disable jsx-a11y/anchor-is-valid */
import { Container, Row,Col, Button, Image } from "react-bootstrap";
import Arrowbutton from '../assets/Arrowbutton.svg'
import Banner from "../components/Banner";
import ProjectsCards from "../components/ProjectsCards";
import AboutUs from "../components/Aboutus";
import LocationServices from "../components/LocationServices";
import Teams from "../components/Teams";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

export default function HomePage() {
    return (
        <>
            <section className="banner-top">
                <Container>
                    <Row>
                        <Col md="5" lg="5" sm="5" xs="12">
                            <p>Your partner for good quality , premium homes in Hyderabad.</p>
                            <div className="buttons mt-3">
                                <Link to={process.env.PUBLIC_URL+'/get-in-touch'} className="btn btn-primary">GET IN TOUCH <Image src={Arrowbutton} alt="Arrowbutton" title="Arrowbutton" /></Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Banner />
            <ProjectsCards />
            <AboutUs />
            <LocationServices />
            <section className="start-living-page">
                <section className="start-living-developer">
                    <Container>
                        <Row>
                            <Col md="7" lg="7" sm="7" xs="12">
                                <div className="startLivingDeveloperBlock">
                                    <h4 className="sub-caption">SAI SUBHAM HOMES</h4>
                                    <h3>SMART LIVING SOLUTIONS DESIGNED & BUILD TO LAST</h3>
                                    <div className="buttons mt-3">
                                        <Button className="btn btn-primary">VIEW ALL <Image src={Arrowbutton} alt="Arrowbutton" title="Arrowbutton" /></Button>
                                    </div>
                                </div>
                            </Col>
                            <Col md="5" lg="5" sm="5" xs="12">
                                <div className="development-image">
                                    <div className="development-image-right">
                                        <Image src={process.env.PUBLIC_URL+'/images/interiorimage1.png'} alt="interiorimage1" title="interiorimage1" />
                                    </div>
                                    <div className="development-image-left space-top">
                                    <Image src={process.env.PUBLIC_URL+'/images/interiorimage2.png'} alt="interiorimage2" title="interiorimage2" />
                                    </div>
                                    <div className="development-image-right space-top">
                                    <Image src={process.env.PUBLIC_URL+'/images/interiorimage3.png'} alt="interiorimage3" title="interiorimage3" />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="best-offer">
                    <section className="pb-60 offer-cards-page border-bottom">
                        <Container>
                            <Row>
                                <Col md="5" lg="5" sm="5" xs="12">
                                <div className="best-offer-image">
                                               <Image src={process.env.PUBLIC_URL+"/images/exteriorimage.png"} alt="exteriorimage"
                                                        title="exteriorimage" />
                                            </div>
                                </Col>
                                <Col md="7" lg="7" sm="7" xs="12" className="self-center mobile-space-top offer-left-shape">
                                    <div className="offer-content offer-content-space-less-top">
                                        <h4 className="offer-sub-content">Launching Soon</h4>
                                        <h2>Sai Subham Vimala Homes at Malkajgiri behind CINEPOLIS.</h2>
                                        <div className="buttons mt-3">
                                           <Link to={process.env.PUBLIC_URL+'/get-in-touch'} className="btn btn-primary">GET IN TOUCH <Image src={Arrowbutton} alt="Arrowbutton" title="Arrowbutton" /></Link>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="pt-60">
                        <Container>
                            <Row>
                                <Col md="7" lg="7" sm="7" xs="12">
                                    <div className="offer-content">
                                        <h2>SAI SUBHAM HOMES<br/>
                                        QUALITY PROMISE</h2>
                                    </div>
                                </Col>
                                <Col md="5" lg="5" sm="5" xs="12" className="mobile-space-top self-center">
                                    <div className="buttons">
                                        <a href={process.env.PUBLIC_URL+'/pdfs/TheSubhamHomesQualityPromise.pdf'} target="_blank" className="btn btn-primary size-20" rel="noreferrer">Click Here to download our Quality Promise  <Image src={Arrowbutton} alt="Arrowbutton" title="Arrowbutton" /></a>
                                    </div>
                                    {/* <Row>
                                        <Col md="6" lg="6" sm="6" xs="12">
                                            <div className="care-block">
                                                <h4>1420 Sq Ft</h4>
                                                <h2>3 Bhk</h2>
                                            </div>
                                        </Col>
                                        <Col md="6" lg="6" sm="6" xs="12">
                                            <div className="care-block">
                                                <h4>1189 sq mt</h4>
                                                <h2>Area</h2>
                                            </div>
                                        </Col>
                                        </Row> */}
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </section>
            </section>
            <section className="cover-bg">
                <section className="development-started">
                    <Container>
                        <Row>
                            <Col md="7" lg="7" sm="7" xs="12">
                                <div className="project-plan-image">
                                    <Image src={process.env.PUBLIC_URL+'/images/3dinteriorimage.svg'} alt="3dinteriorimage" title="3dinteriorimage" className="w-100" />
                                </div>
                                <div className="project-plan-content">
                                    <h6>Two Side Road, Great Ventilation, Easy access to Dairy Farm Road, Access to all markets, stations, schools, bus stops, playgrounds etc.</h6>
                                    <div className="plan-flex mt-3">
                                        <span className="primary">Car Size:</span>
                                        <span>SUV Car parking</span>
                                    </div>
                                    <div className="plan-flex">
                                        <span className="primary">Cabin Size Roof top:</span>
                                        <span>8X10 Feet cabin</span>
                                    </div>
                                    <div className="buttons mtop-30">
                                    <Link to={process.env.PUBLIC_URL+'/get-in-touch'} className="btn btn-primary">GET IN TOUCH <Image src={Arrowbutton} alt="Arrowbutton" title="Arrowbutton" /></Link>
                                    </div>
                                </div>
                            </Col>
                            <Col md="5" lg="5" sm="5" xs="12">
                                <div className="project-block-content">
                                    <h3>Our Floor Plans</h3>
                                    <p>Well planned space efficient, energy efficient and functional smart homes.</p>
                                    <div className="plan-layout">
                                        <Image src={process.env.PUBLIC_URL+'/images/AIPLSubhamGayathriLayout1.png'} alt="AIPLSubhamGayathriLayout" title="AIPLSubhamGayathriLayout" className="w-100" />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            <Teams />
            </section>
        <Footer />
        </>
    )
}