import { Button, Col, Container, Form, Row } from "react-bootstrap";
import InnerFooter from "../components/InnerFooter";
import {  useState } from "react";
import { ContactDetails } from "../services/DataController";

export default function GetIntouch() {
    const [userInfo,setUserInfo] = useState({name:'',email:'',mobileNumber:''})
    // eslint-disable-next-line no-unused-vars
    const [validated, setValidated] = useState(false);
    const [errors,setErrors]=useState(false)
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        }
        else {
            if(userInfo.mobileNumber.length!==10){
                setErrors(true);
                event.preventDefault();
            } else {
                event.preventDefault();
                setErrors(false);
                ContactDetails(userInfo).then((res)=>{
                    if(res.data.success===1) {
                       alert('Thanks for contacting us.our team will get back to you soon.');
                       window.location.reload();
                    } else {
                        alert(res.data.message);
                    }
                }).catch((err)=>{
                    alert('Something went wrong please try again');
                })
            }
        }
        setValidated(true);
    }
    return(
        <>
        <section className="get-in-touch inner-page-top">
            <Container>
                <Row>
                    <Col md="5" lg="5" sm="5" xs="12">
                        <div className="touch-content-block">
                            <h2>Request a quote</h2>
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <Form.Group className="mtop-20">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" required onChange={e=>setUserInfo({...userInfo,name:e.target.value})} value={userInfo.name} placeholder="Enter Full Name |"></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mtop-20">
                                    <Form.Label>Email ID</Form.Label>
                                    <Form.Control type="email" placeholder="Enter Mail ID" required onChange={e=>setUserInfo({...userInfo,email:e.target.value})} value={userInfo.email}></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter Email.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mtop-20">
                                    <Form.Label>Contact Number</Form.Label>
                                    <Form.Control type="number" pattern="[0-9]*" className={errors?'form-control is-invalid' :'form-control'} placeholder="Enter Your Phone Number" required onChange={e=>setUserInfo({...userInfo,mobileNumber:e.target.value})} value={userInfo.mobileNumber}></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter mobileNumber.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <div className="button mtop-30 text-center">
                                    <Button type="submit" className="btn btn-secondary form-btn">Submit</Button>
                                </div>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <div className="video-section">
            <div className="default-min-height"></div>
            <div className="video-full">
                <video width="100%" height="100%" autoPlay loop>
                    <source src={process.env.PUBLIC_URL+'/videos/HappyFamilyAinfra.mp4'} type="video/mp4" />
                    <source src="movie.ogg" type="video/ogg" />
                    Your browser does not support the video tag.
                </video>
            </div>
            <InnerFooter />
        </div>
        </>
        
    )
}