import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (!pathname.hash) {
        window.scrollTo(0, 0);
      }
    const body = document.querySelector('#root');

      body.scrollIntoView({
          behavior: 'smooth'
      }, 500)
      
  }, [pathname]);

  return null;
}
