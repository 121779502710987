import {  Col, Container, Image, Row } from "react-bootstrap";
import Arrowbutton from '../assets/Arrowbutton.svg'
import { Link } from "react-router-dom";
export default function ProjectsCards() {
    return(
        <>
            <section className="projects-cards">
                <Container>
                    <Row>
                        <Col md="6" lg="6" sm="6" xs="12" className="self-center">
                            <div className="project-card-image">
                                <Image src={process.env.PUBLIC_URL+'/projects/SubhamGayatri.png'} alt="SubhamGayatri" title="SubhamGayatri" className="w-100" />
                            </div>
                        </Col>
                        <Col md="6" lg="6" sm="6" xs="12" className="self-center">
                            <Row>
                                <Col md="7" lg="7" sm="7" xs="12" className="self-center">
                                    <div className="project-card">
                                        <h6>DAIRY FARM ROAD</h6>
                                        <h3>SAI SUBHAM GAYATRI</h3>
                                        <p>A Home + Office concept homes at DAIRY FARM Road, with independent home on each floor.</p>
                                        <div className="buttons mt-3">
                                        <Link to={process.env.PUBLIC_URL+'/get-in-touch'} className="btn btn-primary">GET IN TOUCH <Image src={Arrowbutton} alt="Arrowbutton" title="Arrowbutton" /></Link>
                                        </div>
                                    </div>
                                </Col>
                                <Col md="5" lg="5" sm="5" xs="12" className="self-center">
                                    <div className="project-card-build-image">
                                    <Image src={process.env.PUBLIC_URL+'/projects/SubhamGayatribuilding.png'} alt="SubhamGayatribuilding" title="SubhamGayatribuilding" className="w-100" />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col md="6" lg="6" sm="6" xs="12" className="self-center">
                            <Row>
                                <Col md="5" lg="5" sm="5" xs="12" className="self-center">
                                    <div className="project-card-build-image">
                                    <Image src={process.env.PUBLIC_URL+'/projects/saisubhamvimalabuiliding.png'} alt="saisubhamvimalabuiliding" title="saisubhamvimalabuiliding" className="w-100" />
                                    </div>
                                </Col>
                                <Col md="7" lg="7" sm="7" xs="12" className="self-center">
                                    <div className="project-card pl-none">
                                        <h6>MALKAJGIRI, BEHIND CINEPOLIS</h6>
                                        <h3>SAI SUBHAM VIMALA</h3>
                                        <p>A Home+Office concept Independent abode, behind CINEPOLIS, Malkajgiri in Vimala Nagar.</p>
                                        <div className="buttons mt-3">
                                        <Link to={process.env.PUBLIC_URL+'/get-in-touch'} className="btn btn-primary">GET IN TOUCH <Image src={Arrowbutton} alt="Arrowbutton" title="Arrowbutton" /></Link>
                                        </div>
                                    </div>
                                </Col>
                                
                            </Row>
                        </Col>
                        <Col md="6" lg="6" sm="6" xs="12" className="mobile-space-top self-center">
                            <div className="project-card-image">
                                <Image src={process.env.PUBLIC_URL+'/projects/Sitaphalmandi.png'} alt="Sitaphalmandi" title="Sitaphalmandi" className="w-100" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col md="6" lg="6" sm="6" xs="12" className="self-center">
                            <div className="project-card-image">
                                <Image src={process.env.PUBLIC_URL+'/projects/saisubhamsita.png'} alt="saisubhamsita" title="saisubhamsita" className="w-100" />
                            </div>
                        </Col>
                        <Col md="6" lg="6" sm="6" xs="12" className="self-center">
                            <Row>
                                <Col md="7" lg="7" sm="7" xs="12" className="self-center">
                                    <div className="project-card">
                                        <h6>Launching on this Christmas</h6>
                                        <h3>SAI SUBHAM SITA</h3>
                                        <p>Launching Soon Sai Subham Sita Homes - Home + Office Concept homes near Sitaphalmandi Railway station. </p>
                                        <div className="buttons mt-3">
                                        <Link to={process.env.PUBLIC_URL+'/get-in-touch'} className="btn btn-primary">GET IN TOUCH <Image src={Arrowbutton} alt="Arrowbutton" title="Arrowbutton" /></Link>
                                        </div>
                                    </div>
                                </Col>
                                <Col md="5" lg="5" sm="5" xs="12" className="self-center">
                                    <div className="project-card-build-image">
                                    <Image src={process.env.PUBLIC_URL+'/projects/saisubhamsitabuilding.png'} alt="saisubhamsitabuilding" title="saisubhamsitabuilding" className="w-100" />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}