import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ScrollToTop from '../components/ScrollToTop';
import Header from '../components/Header';
import HomePage from '../views/HomePage';
import GetIntouch from '../views/GetInTouch';
import { useEffect } from 'react';
import PrivacyPolicy from '../views/PrivacyPolicy';
export const RouterNavigation = (props) => {
    useEffect(()=>{
        console.log(window.location.pathname)
      },[])
      
    return (
        <>
         <Router>
         <ScrollToTop />
           <Header />
           <Switch>
                 <Route exact path="/" component={HomePage} />
                 <Route path="/get-in-touch" component={GetIntouch} />
                 <Route path="/privacy-policy" component={PrivacyPolicy} />
            </Switch>
            </Router>
         </>
    )
}