import { Col, Container, Image, Row } from "react-bootstrap";
import Arrowbutton from '../assets/Arrowbutton.svg'
import { Link } from "react-router-dom";
import SubhamHomesLogo from '../assets/subham_logo-SVG.svg'
export default function LocationServices() {
    return(
        <section className="location-services">
            {/* <section className="location-name-display border-bottom">
                <Container>
                    <Row>
                        <Col md="12" lg="12" sm="12" xs="12">
                            <div className="flex">
                                <span><Image src={process.env.PUBLIC_URL+'/ecillipse.svg'} alt="Ecillipse" title="Ecillipse" /></span>
                                <h6 className="location-name">Mylargadda, Sitaphalmandi, Secunderabad, Telangana , India.</h6>
                            </div>
                        </Col>
                    </Row>
                </Container>
    </section> */}
            <section className="border-bottom">
                <Container>
                    <Row>
                        <Col md="6" lg="6" sm="6" xs="12" className="self-center">
                            <div className="highlights-content">
                                <div className="subham-logo">
                                    <Image src={SubhamHomesLogo} alt="SAI SUBHAM HOMES" title="SAI SUBHAM HOMES" />
                                </div>
                                {/* <h4 className="sub-caption">SAI SUBHAM HOMES</h4> */}
                                <h2 className="section-white-title">A Home+Office concept with energy efficient design.</h2>
                                <p>All our homes are energy efficient with excellent ventilation and solar infrastructure. Each home is planned keeping energy and natural resource conservation in mind there by bringing in innovative water storage and plumbing solutions mixed with solar infrastructure that helps with energy efficiency.</p>
                                <div className="button mt-5">
                                <Link to={process.env.PUBLIC_URL+'/get-in-touch'} className="btn btn-primary">GET IN TOUCH <Image src={Arrowbutton} alt="Arrowbutton" title="Arrowbutton" /></Link>
                                </div>
                            </div>
                        </Col>
                        <Col md="6" lg="6" sm="6" xs="12" className="border-left-color pb-30">
                            <Row className="mtop-30">
                                <Col md="2" lg="2" sm="2" xs="4">
                                    <div className="location-images">
                                        <Image src={process.env.PUBLIC_URL+'/location-highlights/SolarInfrastructure.svg'} alt="Solar Infrastructure And Net Metering" title="Solar Infrastructure And Net Metering" className="w-100" />
                                    </div>
                                </Col>
                                <Col md="10" lg="10" sm="10" xs="8">
                                    <div className="location-content">
                                        <h6 className="location-title">Solar Infrastructure And Net Metering</h6>
                                        <p>All our homes are ready for solar infrastructure and wiring has been done with net metering in mind, so that from day one you have an energy efficient home with cloud mapping of the solar production and net metering.</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mtop-20">
                                <Col md="2" lg="2" sm="2" xs="4">
                                    <div className="location-images">
                                        <Image src={process.env.PUBLIC_URL+'/location-highlights/Vastu.svg'} alt="Vastu Compliance And Ventilation" title="Vastu Compliance And Ventilation" className="w-100" />
                                    </div>
                                </Col>
                                <Col md="10" lg="10" sm="10" xs="8">
                                    <div className="location-content">
                                        <h6 className="location-title">Vastu Compliance And Ventilation</h6>
                                        <p>Our homes are 100% vastu compliant with certified vastu plans and complete compliance to  the Vastu Science with minimal functional deviations.</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mtop-20">
                                <Col md="2" lg="2" sm="2" xs="4">
                                    <div className="location-images">
                                        <Image src={process.env.PUBLIC_URL+'/location-highlights/IndependentFloor.svg'} alt="Independent Floor With Private Lobby" title="Independent Floor With Private Lobby" className="w-100" />
                                    </div>
                                </Col>
                                <Col md="10" lg="10" sm="10" xs="8">
                                    <div className="location-content">
                                        <h6 className="location-title">Independent Floor With Private Lobby</h6>
                                        <p>Each home is independent house planned with most care to make sure that complete floor is only habituated with one family only. Spacious lobbies, balconies and halls are special features of our design.</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mtop-20">
                                <Col md="2" lg="2" sm="2" xs="4">
                                    <div className="location-images">
                                        <Image src={process.env.PUBLIC_URL+'/location-highlights/UsableEfficientRoof.svg'} alt="Usable Efficient Roof Tops With Office Rooms" title="Usable Efficient Roof Tops With Office Rooms" className="w-100" />
                                    </div>
                                </Col>
                                <Col md="10" lg="10" sm="10" xs="8">
                                    <div className="location-content">
                                        <h6 className="location-title">Usable Efficient Roof Tops With Office Rooms</h6>
                                        <p>Each home is provided with a roof top office space, clubbed with completely covered fabricated roof top for garden space and solar installations.  Each roof top will also have covered usable party space.</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mtop-20">
                                <Col md="2" lg="2" sm="2" xs="4">
                                    <div className="location-images">
                                        <Image src={process.env.PUBLIC_URL+'/location-highlights/ElectronicVehicle.svg'} alt="Electronic Vehicle Ready Car Parks" title="Electronic Vehicle Ready Car Parks" className="w-100" />
                                    </div>
                                </Col>
                                <Col md="10" lg="10" sm="10" xs="8">
                                    <div className="location-content">
                                        <h6 className="location-title">Electronic Vehicle Ready Car Parks</h6>
                                        <p>Our car garages are efficiently designed to use both ceiling space and ground space for bicycles and cars. And they are EV Ready with plug and play setup with efficient storage for your car spares that need not find their way into living spaces.</p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="location-services-card">
                <Container>
                    <Row>
                        <Col md="4" lg="4" sm="4" xs="12">
                            <div className="service-block">
                                <div className="service-image">
                                   <Image src={process.env.PUBLIC_URL+'/services/ghmc.png'} alt="GHMC Approval" title="GHMC Approval" />
                                </div>
                                <div className="service-content">
                                    <h4>GHMC Approved</h4>
                                    <p>All our properties are 100% GHMC approved and loanable bankable properties.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md="4" lg="4" sm="4" xs="12">
                            <div className="service-block">
                                <div className="service-image">
                                   <Image src={process.env.PUBLIC_URL+'/services/vastu.png'} alt="Vastu Compliant" title="Vastu Compliant" />
                                </div>
                                <div className="service-content">
                                    <h4>100% Legal</h4>
                                    <p>We make sure that we deal with 100% legal and verified properties ,so that the dream homes of our customers give  a great ROI appreciation also to the clients</p>
                                </div>
                            </div>
                        </Col>
                        <Col md="4" lg="4" sm="4" xs="12">
                            <div className="service-block">
                                <div className="service-image">
                                   <Image src={process.env.PUBLIC_URL+'/services/energyconservative.png'} alt="Energy Conservative" title="Energy Conservative" />
                                </div>
                                <div className="service-content">
                                    <h4>Prime Locations</h4>
                                    <p>We make sure that we choose our projects in prime locations ,so that the decision making for the customers to buy is easy.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </section>
    )
}