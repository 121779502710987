import { Image } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Ainfralogo from '../assets/Ainfralogo.png'
import { Link, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { HashLink } from 'react-router-hash-link';
function Header() {
  const {pathname} = useLocation()
  const [expanded, setexpanded] = useState(false);
  return (
    <Navbar expand="lg" className="bg-transparent header" id="header">
      <Container>
      <Link to={process.env.PUBLIC_URL} onClick={()=>{setexpanded(false)}} className="navbar-brand">
            <Image src={Ainfralogo} alt="Ainfralogo" title='Ainfralogo' className='logo' />
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setexpanded(expanded ? false : "expanded")} />
        <Navbar.Collapse id="basic-navbar-nav" className='justify-content-end'>
          <Nav className="m1-auto">
            <Link to={process.env.PUBLIC_URL} onClick={()=>{setexpanded(false)}} className={pathname===''?"active nav-link":pathname==='/'?"active nav-link":'nav-link'}>HOME</Link>
            <HashLink to={process.env.PUBLIC_URL+'/#aboutus'} className={pathname==='/#aboutus'?"active nav-link":'nav-link'} onClick={()=>{setexpanded(false)}}>ABOUT</HashLink>
            <Nav.Link href="" onClick={()=>{setexpanded(false)}}>PROJECTS</Nav.Link>
            <Nav.Link href="" onClick={()=>{setexpanded(false)}}>GALLERY</Nav.Link>
            <Link to={process.env.PUBLIC_URL+'/get-in-touch'} className={pathname==='/get-in-touch'?"active nav-link last":'nav-link last'} onClick={()=>{setexpanded(false)}}>CONTACT</Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;